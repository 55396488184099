<template>
  <div>
    <modal-yield-playgrounds
      v-if="showYieldPlayground"
      :showModal="showYieldPlayground"
      :yieldSettings="yieldSettingsState.yieldSettings"
      :onClickCancel="closeEditModal"
    />

    <nav class="navbar">
      <a @click="$router.go(-1)" class="navbar-item">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span>Back</span>
      </a>
    </nav>

    <section v-if="yieldSettingsState.yieldSettings" class="section is-small">
      <div class="container">
        <div
          class="actionBar has-background-white-ter has-padding has-margin-bottom"
        >
          <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
              <a @click="setShowYieldPlayground" class="is-size-7">
                <span class="icon">
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    aria-hidden="true"
                  />
                </span>
                <span>Open playground</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="yieldSettingsState.yieldSettings" class="section is-small">
      <div class="container">
        <YieldPrices />
      </div>
    </section>

    <section v-if="yieldSettingsState.yieldSettings" class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="box">
              <yield-location-utilization />
            </div>
          </div>
          <div class="column">
            <div class="box">
              <yield-space-utilization />
            </div>
          </div>
        </div>

        <div v-if="yieldSettingsState.yieldSettings" class="columns">
          <div class="column">
            <div class="box">
              <yield-day-percentages />
            </div>
          </div>
          <div class="column">
            <div class="box">
              <yield-group-sizes />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'
import yieldProvider from '@/providers/yield'

const YieldDayPercentages = () => import('@/components/Yield/DayPercentages')
const YieldGroupSizes = () => import('@/components/Yield/GroupSizes')
const YieldLocationUtilization = () =>
  import('@/components/Yield/LocationUtilization')
const YieldPrices = () => import('@/components/Yield/Prices')
const YieldSpaceUtilization = () =>
  import('@/components/Yield/SpaceUtilization')

const ModalYieldPlayground = () =>
  import('@/components/Yield/Playground/ModalYieldPlaygrounds')

export default {
  components: {
    'yield-day-percentages': YieldDayPercentages,
    'yield-group-sizes': YieldGroupSizes,
    'yield-location-utilization': YieldLocationUtilization,
    YieldPrices,
    'yield-space-utilization': YieldSpaceUtilization,

    'modal-yield-playgrounds': ModalYieldPlayground,
  },

  data() {
    return {
      locationId: Number(this.$route.params.locationId),
      showYieldPlayground: false,
      yieldId: Number(this.$route.params.yieldId),
    }
  },

  computed: {
    //...mapState('locationStore', ['locationState']),
    ...mapState('yieldStore', ['yieldSettingsState']),
  },

  created() {
    if (!this.yieldSettingsState) {
      this.getYieldSettings()
    }
  },

  beforeDestroy() {
    this.setYieldSettingsState({
      yieldSettings: null,
    })
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    getYieldSettings() {
      let self = this

      yieldProvider.methods
        .getYieldSeetingsById(self.yieldId)
        .then((response) => {
          if (response.status === 200) {
            this.setYieldSettingsState({
              isSaving: false,
              isSavingSuccess: false,
              isSavingError: false,
              yieldSettings: response.data,
            })

            self.setYieldSettingsState(this.setYieldSettingsState)

            self.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    setShowYieldPlayground() {
      this.showYieldPlayground = true

      let yieldSettings = JSON.parse(
        JSON.stringify(this.yieldSettingsState.yieldSettings)
      )
      this.setYieldSettingsPlayground(yieldSettings)
    },

    closeEditModal() {
      this.showYieldPlayground = false
    },
  },
}
</script>
